<template>
  <div class="personal-id">
    <input type="tel" inputmode="numeric" class="form-control" pattern="[0-9]*" maxlength="6" @input="input($event)" @keyup.enter="typeof enter === 'function' && enter()" :readonly="readonly" :disabled="disabled" v-model="state.start"
           :class="[className || 'border-focus-point']" :id="id"/>
    <span class="dash">-</span>
    <input type="password" inputmode="numeric" class="form-control" pattern="[0-9]*" maxlength="7" @input="input($event)" @keyup.enter="typeof enter === 'function' && enter()" :readonly="readonly" :disabled="disabled" v-model="state.end"
           :class="[className || 'border-focus-point']" autocomplete="new-password" :id="`${id}Password`"/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPersonalId";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    value: Number | String,
    readonly: Boolean,
    disabled: Boolean,
    className: String,
    noComma: Boolean,
    allowZero: Boolean,
    enter: Function,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      if (props.value && props.value.includes("-")) {
        const split = props.value.split("-");
        state.start = window.Number(split[0]) || null;
        state.end = window.Number(split[1]) || null;
      } else {
        state.start = props.value;
      }
    });

    const state = reactive({
      start: null,
      end: null,
    });

    const input = (e) => {
      e.target.value.replace(/[^0-9]/gi, "");
      emit("update:value", `${state.start}-${state.end}`);
    };

    return {component, state, input};
  }
});
</script>

<style lang="scss" scoped>
.personal-id {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dash {
    padding: 0 $px10;
  }
}
</style>
<template>
  <div class="step" :class="{skeleton: skeleton, last: last}">
    <div class="top">
      <div class="info">
        <label class="title" :for="component.name + item.type" v-if="!item.hideTitle">
          <span class="color-point" v-if="item.required">* </span>
          <span>{{ item.title }}</span>
        </label>
        <span class="desc" v-if="item.description">{{ item.description }}</span>
      </div>
      <div class="side">
        <button class="btn btn-default" v-if="item.type === 'file'" @click="browse()" :id="component.name + item.type" :disabled="disabled">파일 첨부</button>
      </div>
    </div>
    <div class="content">
      <ul class="tight files" v-if="item.type === 'file'">
        <template v-if="Array.isArray(answer.texts) && answer.texts.length">
          <li class="file" v-for="(t, idx) in answer.texts" :key="idx">
            <span class="name">{{ t }}</span>
            <span class="remove" title="삭제" @click="remove(idx , true)" v-if="!disabled">&times;</span>
          </li>
        </template>
        <template v-if="Array.isArray(files) && files.length">
          <li class="file" v-for="(v, idx) in files" :key="answer.texts.length + idx">
            <span class="name">{{ v.name || v.fileOriginName }}</span>
            <span class="remove" title="삭제" @click="remove(idx)">&times;</span>
          </li>
        </template>
      </ul>
      <div class="select" v-else-if="item.type === 'select'">
        <select :id="component.name + item.type" class="form-control border-focus-point font-sm" v-model="answer.value" :disabled="disabled">
          <option :value="null" hidden selected>옵션을 선택해주세요</option>
          <option :value="o.id" v-for="(o, idx) in item.options" :key="idx">{{ o.title }}</option>
        </select>
      </div>
      <div class="check" v-else-if="['checkbox', 'radio'].includes(item.type)">
        <label v-for="(o, idx) in item.options" :key="idx" :for="component.name + item.type + idx">
          <input :id="component.name + item.type + idx" class="border-focus-point font-sm" :type="item.type" v-model="answer.value" :value="o.id" :name="component.name + item.type" :disabled="disabled">
          <span>{{ o.title }}</span>
        </label>
      </div>
      <div class="tel" v-else-if="item.type === 'tel'">
        <Phone :componentNameSuffix="item.id.toString()" :value.sync="answer.value" placeholder="ex) 01012345678" :id="component.name + item.type" :disabled="disabled"/>
      </div>
      <div class="textarea" v-else-if="item.type === 'textarea'">
        <textarea class="form-control border-focus-point font-sm" v-model="answer.value" :id="component.name + item.type" :disabled="disabled"></textarea>
      </div>
      <div class="date" v-else-if="item.type === 'date'">
        <Date :componentNameSuffix="item.id.toString()" class="font-sm" :id="component.name + item.type" :value.sync="answer.value" :disabled="disabled"/>
      </div>
      <div class="number" v-else-if="['number', 'currency'].includes(item.type)">
        <Number :componentNameSuffix="item.id.toString()" :id="component.name + item.type" :value.sync="answer.value" :allowZero="true" :noComma="item.type !== 'currency'" :disabled="disabled"/>
      </div>
      <div class="personal-id" v-else-if="item.type === 'personalId'">
        <PersonalId :componentNameSuffix="item.id.toString()" :value.sync="answer.value" :id="component.name + item.type" :disabled="disabled"/>
      </div>
      <div class="choose" :class="item.type" v-else-if="['agreement', 'checklist'].includes(item.type)">
        <div class="content">
          <span>{{ item.content }}</span>
        </div>
        <div class="inputs">
          <div class="subject font-xs" v-if="item.type === 'checklist'">위 내용에 해당되시나요?</div>
          <div>
            <label v-for="(o, idx) in item.options" :key="idx" :for="component.name + item.type + idx ">
              <input :id="component.name + item.type + idx" :name="component.name + item.type" class="border-focus-point font-sm"
                     type="checkbox" :true-value="o.id" :false-value="null" v-model="answer.value" :disabled="disabled" v-if="item.options.length === 1"/>
              <input :id="component.name + item.type + idx" :name="component.name + item.type" class="border-focus-point font-sm" type="radio" :value="o.id" v-model="answer.value" :disabled="disabled" v-else/>
              <span>{{ o.title }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="text" v-else>
        <input class="form-control border-focus-point font-sm" :type="item.type" :id="component.name + item.type" :class="item.type" v-model="answer.value" :disabled="disabled"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import Phone from "@/components/Phone";
import Date from "@/components/Date";
import Number from "@/components/Number";
import PersonalId from "@/components/PersonalId";

function Component(initialize) {
  this.name = "pageFormStep";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PersonalId, Number, Date, Phone},
  mixins: [mixin],
  props: {
    item: Object,
    skeleton: Boolean,
    disabled: Boolean,
    answer: Object,
    files: Array,
    last: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const browse = () => {
      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files`,
        },
      });
    };

    const remove = (idx, saved) => {
      if (saved) {
        props.answer.value.splice(idx, 1);
        props.answer.texts.splice(idx, 1);
      } else {
        props.files.splice(idx, 1);
      }
    };

    return {component, browse, remove};
  }
});
</script>

<style lang="scss" scoped>
.step {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: $px4;
    gap: $px25;

    .info {
      .title {
        font-size: $px13;
        margin-bottom: $px5;
      }

      .desc {
        color: #aaa;
        display: block;
        font-size: $px12;
        white-space: pre-line;
        margin-bottom: $px5;
      }
    }

    .uploader {
      background-color: rgba($colorPurple, 20%);
      border-radius: $px4;
      color: $colorPurple;
      cursor: pointer;
      display: inline-block;
      font-size: $px12;
      padding: $px8 $px12;
    }

    > .side {
      .btn {
        font-size: $px12;
        padding: $px10 $px15;
        margin-top: 0;
        white-space: nowrap;

        &:focus {
          background: $colorSecondaryActive;
          color: #fff;
        }
      }
    }
  }

  .content {
    > .files {
      margin-top: $px10;

      .file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7f7f7;
        padding: $px15;
        border: 1px solid #eee;
        border-radius: $px4;

        .name {
          font-size: $px14;
          line-height: 1;

          &:before {
            background: url("/assets/ico/modal.uploader.file.svg") center center/cover no-repeat;
            content: " ";
            display: inline-block;
            width: $px16;
            height: $px16;
            vertical-align: middle;
            margin-right: $px10;
          }
        }

        .remove {
          cursor: pointer;
          padding: 0;
          font-size: $px11;
        }

        &:not(:last-child) {
          margin-bottom: $px7;
        }
      }
    }

    > div {
      label {
        display: inline-block;
        font-size: $px12;
        vertical-align: middle;
        margin: $px8 $px8 0 $px8;

        > input {
          margin-right: $px4;
          vertical-align: middle;
        }

        > span {
          vertical-align: middle;
        }
      }

      &.check {
        font-size: 0;
        margin-left: $px-5;

        label {
          margin-top: 0;
        }
      }

      &.choose {
        .content {
          border-radius: $px4;
          background: $colorBackground;
          padding: $px20;
          font-size: $px12;
          color: $colorSecondary;
          white-space: pre-line;
        }

        .inputs {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: $px20 0;

          label {
            margin: 0 0 0 $px15;
          }

          > div {
            font-size: 0;

            &.subject {
              margin-right: $px5;
            }
          }
        }

        &.agreement {
          .inputs {
            justify-content: end;
            text-align: center;
          }
        }

        &.checklist {
          .content {
            color: inherit;
            font-size: $px12;
            border-color: $colorBorder;
          }
        }
      }
    }

    &::v-deep {
      input:not([type='checkbox']):not([type='radio']), select {
        height: $px42;
      }
    }
  }

  &.last {
    .content > div.choose .inputs {
      padding-bottom: 0;
    }
  }

  &.skeleton {
    .top {
      .info, .side {
        label, span, button {
          @include skeleton;
        }
      }
    }

    .content {
      > ul li {
        @include skeleton;

        span:before {
          @include skeleton;
        }
      }

      > div {
        input, textarea, select {
          @include skeleton;
        }
      }
    }
  }
}
</style>